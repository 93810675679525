import { GraphQLClient } from 'graphql-request';
import getConfig from 'next/config';
export { gql } from 'graphql-request';
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
export const hasuraAdminClient = new GraphQLClient(
  publicRuntimeConfig.GRAPHQL_ENDPOINT,
  {
    headers: {
      'X-Hasura-Admin-Secret': serverRuntimeConfig.HASURA_GRAPHQL_ADMIN_SECRET
    }
  }
);
