import { GraphQLClient } from 'graphql-request';
import * as neo4j from 'neo4j-driver';

export const neoGQLConnection = `http://localhost:${
  process.env.PORT || 4000
}/graphql`;
export const hasuraGQLConnection = `https://hasura-one-hpybobsvcq-uc.a.run.app/v1/graphql`;
export const neo4jUri =
  process.env.NEO4J_BOLT_ENDPOINT || 'bolt://34.66.101.137:7687';

// export function wevrCommon(): string {
//   return 'wevr-common';
// }
export function createNeoGqlClient(): GraphQLClient {
  return new GraphQLClient(neoGQLConnection, {
    headers: {}
  });
}

export function createHasuraGqlClient(): GraphQLClient {
  return new GraphQLClient(hasuraGQLConnection, {
    headers: {
      'x-hasura-admin-secret': process.env.HASURA_GRAPHQL_ADMIN_SECRET ||'qwe123QWE!@#'
    }
  });
}

export const neo4jdriver = neo4j.driver(
  neo4jUri,
  neo4j.auth.basic('neo4j', process.env.NEO4J_BOLT_PASSWORD || 'qwe123QWE!@#')
);
