import { useUser } from '@auth0/nextjs-auth0';
import { ROLES } from '@wevr/common';
import { hasRole } from 'lib/role-check';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';

export default function useWEVRUser() {
  const { user, error, isLoading } = useUser();
  const [permissions, setPermissions] = useState([]);
  const isAdmin = hasRole(user, ROLES.ADMIN);
  const isPartMaster = hasRole(user, ROLES.PART_MASTER);
  const isBomMaster = hasRole(user, ROLES.BOM_MASTER);
  const isBLOX = hasRole(user, ROLES.BLOX);
  const hasPermission = (perm: string | string[]) => {
    const toCompare = Array.isArray(perm) ? perm : [perm];
    return _.intersection(permissions, toCompare).length > 0;
  };

  useEffect(() => {
    if (user) {
      const traits = {
        name: user.first_name
          ? user.first_name + ' ' + user.last_name
          : user.email,
        email: user.email
      };
      window['analytics']?.identify(user.sub, traits);
      fetch('/api/auth/permissions')
        .then((res) => res.json())
        .then(({ permissions }) => {
          setPermissions(permissions);
        });
    }
  }, [user]);

  return {
    error,
    isAdmin,
    isBLOX,
    isBomMaster,
    isLoading,
    isPartMaster,
    user,
    permissions,
    hasPermission,
    hasRole
  };
}
