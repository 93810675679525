import themes from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';
import useWindowSize from '@iso/lib/hooks/useWindowSize';
import appActions from '@iso/redux/app/actions';
import { ConfigProvider } from 'antd';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

const { toggleAll } = appActions;

const AppProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { themeName } = useSelector(
    (state) => state.ThemeSwitcher.changeThemes
  );
  const currentAppLocale = AppLocale[locale];
  const { width, height } = useWindowSize();

  useEffect(() => {
    dispatch(toggleAll(width, height));
  }, [dispatch, width, height]);
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default AppProvider;
