export const ROLES = {
  ADMIN: 'admin',
  BLOX: 'blox',
  BOM_MASTER: 'bom-master',
  CLIENT_ADMIN: 'client_admin',
  CLIENT_USER: 'client_user',
  PART_MASTER: 'part-master',
  PUBLIC: 'public'
};

export const PERMISSIONS = {
  CAN_ACCESS_DEV: 'access:dev',
  CAN_APPROVE_CONSUMABLE: 'approve:consumable',
  CAN_APPROVE_FASTENERS: 'approve:fastener',
  CAN_APPROVE_PART: 'approve:part',
  CAN_EDIT_BOMS: 'edit:boms',
  CAN_EDIT_MANUFACTURERS: 'edit:manufacturers',
  CAN_EDIT_PARTS: 'edit:parts',
  CAN_EDIT_PARTS_AE: 'edit:parts:ae',
  CAN_EDIT_PARTS_FI: 'edit:parts:fi',
  CAN_EDIT_PARTS_KB: 'edit:parts:kb',
  CAN_EDIT_PARTS_SC: 'edit:parts:sc',
  CAN_EDIT_SUPPLIERS: 'edit:suppliers',
  CAN_EDIT_TAGS: 'edit:tags',
  CAN_VIEW_BOMS: 'view:boms',
  CAN_VIEW_PARTS: 'view:parts'
};
