const typeStyleMap = {
  customer: {
    color: '#79b647',
    nameLabel: 'Customer'
  },
  customerNode: {
    color: '#79b647',
    nameLabel: 'Customer'
  },
  domain: {
    color: '#dfe3e8',
    nameLabel: 'Domain'
  },
  domainNode: {
    color: '#dfe3e8',
    nameLabel: 'Domain'
  },
  part: {
    color: '#a2d977',
    nameLabel: 'Item'
  },
  itemNode: {
    color: '#a2d977',
    nameLabel: 'Item'
  },
  platform: {
    color: '#ef8c22',
    nameLabel: 'Product Line'
  },
  platformNode: {
    color: '#ef8c22',
    nameLabel: 'Product Line'
  },
  process: {
    color: '#c92d39',
    nameLabel: 'Process'
  },
  processNode: {
    color: '#c92d39',
    nameLabel: 'Process'
  },

  program: {
    color: '#1071e5',
    nameLabel: 'Program'
  },
  programNode: {
    color: '#1071e5',
    nameLabel: 'Program'
  },
  project: {
    color: '#6db1ff',
    nameLabel: 'Project'
  },
  projectNode: {
    color: '#6db1ff',
    nameLabel: 'Project'
  },
  order: {
    color: '#c92d39',
    nameLabel: 'Order'
  },
  base_product: {
    color: '#b291b5',
    nameLabel: 'Product'
  },
  product: {
    color: '#b291b5',
    nameLabel: 'Product'
  },
  productNode: {
    color: '#b291b5',
    nameLabel: 'Product'
  },
  product_option: {
    color: '#0c7cba',
    nameLabel: 'Product Option'
  },
  productOptionNode: {
    color: '#0c7cba',
    nameLabel: 'Product Option'
  },
  product_option_choice: {
    color: '#83BBe5',
    nameLabel: 'Product Option Selection'
  },
  choiceNode: {
    color: '#83BBe5',
    nameLabel: 'Product Option Selection'
  },
  choice: {
    color: '#83BBe5',
    nameLabel: 'Product Option Selection'
  },
  unit: {
    color: '#dedeff',
    nameLabel: 'Unit'
  },
  unitNode: {
    color: '#dedeff',
    nameLabel: 'Unit'
  }
};

export default typeStyleMap;
