import typeStyleMap from 'lib/graph-vis/typeStyleMap';
import { ReactComponent as SvgImage } from '../../../public/images/svg/item.svg';

export const ItemIcon = (props) => (
  <SvgImage height={props.height || 24} style={{ fill: typeStyleMap.itemNode.color }} {...props} />
);

export const ItemTagIcon = (props) => (
  <span role="img" aria-label="item tag" className="anticon anticon-item-tag">
    <SvgImage height={15} fill={'antiquewhite'} {...props} />
  </span>
);

export default ItemIcon;
