import typeStyleMap from 'lib/graph-vis/typeStyleMap';
import { ReactComponent as SvgImage } from '../../../public/images/svg/process.svg';

export const ProcessIcon = (props) => (
  <SvgImage
    height={props.height || 24}
    style={{ fill: typeStyleMap.processNode.color }}
    {...props}
  />
);

export const ProcessTagIcon = (props) => (
  <span role="img" aria-label="process tag" className="anticon anticon-process-tag">
    <SvgImage style={{ margin: '2px 0 -2px 0' }} height={15} fill={'antiquewhite'} {...props} />
  </span>
);

export default ProcessIcon;
