import { ROLES } from '@wevr/common';
import _ from 'lodash';
export { ROLES } from '@wevr/common';

const userRoles = (user) => {
  const claims =
    user && user['https://hasura.io/jwt/claims']
      ? user['https://hasura.io/jwt/claims']
      : {
        'x-hasura-allowed-roles': []
      };
  return claims['x-hasura-allowed-roles'] || [];
};

export const hasAdminRole = (user) => {
  return userRoles(user).includes(ROLES.ADMIN);
};

export const hasRole = (user, role): boolean => {
  if (!user) return false;
  const roles = userRoles(user);
  if (!roles) return false;
  const toCompare = Array.isArray(role) ? role : [role];
  return _.intersection(roles, toCompare).length > 0;
};

export const hasPartMasterRole = (user) => {
  if (user && (hasRole(user, ROLES.PART_MASTER) || hasAdminRole(user))) {
    return true;
  }
  return false;
};

export const hasBLOXRole = (user) => {
  if (user && (hasRole(user, ROLES.BLOX) || hasAdminRole(user))) {
    return true;
  }
  return false;
};

export const hasPermission = (user, permission: string) => {
  if (user && user.permissions && user.permissions.includes(permission)) {
    return true;
  }
  return false
}

export const hasBomMasterRole = (user) => {
  if (user && (hasRole(user, ROLES.BOM_MASTER) || hasAdminRole(user))) {
    return true;
  }
  return false;
};

const roleCheck = {
  ROLES,
  hasRole,
  hasAdminRole,
  hasBLOXRole,
  hasPartMasterRole,
  hasBomMasterRole
};

export default roleCheck;
