import '@ant-design/compatible/assets/index.css';
import { UserProvider } from '@auth0/nextjs-auth0';
import '@glidejs/glide/dist/css/glide.core.min.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import 'antd/dist/antd.css';
import { BomProvider } from 'lib/context/BomContext';
import { GlobalProvider } from 'lib/context/GlobalContext';
import withRedux from 'next-redux-wrapper';
import React, { useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import ThemeProvider from '../containers/ThemeProvider';
import '../public/css/global.scss';
import '../public/css/ionicons.min.css';
import initStore from '../redux/store';

LicenseManager.setLicenseKey(
  'CompanyName=BLOX,LicensedApplication=wevr,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-032468,SupportServicesEnd=31_August_2023_[v2]_MTY5MzQzNjQwMDAwMA==b3bdc606ad72de551e909edcf5bbac34'
);

function CustomApp(props) {
  const { Component, pageProps, store } = props;
  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps?.dehydratedState}>
        <Provider store={store}>
          <UserProvider>
            <GlobalProvider>
              <ThemeProvider>
                <BomProvider>
                  <Component {...pageProps} />
                  {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
                </BomProvider>
              </ThemeProvider>
            </GlobalProvider>
          </UserProvider>
        </Provider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default withRedux(initStore)(CustomApp);
