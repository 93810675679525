import { ROLES } from '@wevr/common';
import { getGraphQLClient } from 'lib/hasura-user-client';
import { hasuraAdminClient } from '../hasura-admin-client';
import { getSdk, getSdkWithHooks } from './react';
export * from './react';

export const serverSdk = getSdk(hasuraAdminClient);
export const serverSdkWithHooks = getSdkWithHooks(hasuraAdminClient);
export const clientSdk = (role = ROLES.BLOX) => getSdk(getGraphQLClient(role));
export const clientSdkWithHooks = (role = ROLES.BLOX) =>
  getSdkWithHooks(getGraphQLClient(role));

const sdks = { serverSdk, serverSdkWithHooks, clientSdk, clientSdkWithHooks };
export default sdks;
