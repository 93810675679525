import { all, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import fakeinitdata from './config';
import fake from './fake';

export function* changedCard() {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  yield takeEvery(actions.CHANGE_CARDS, function* () {});
}
export function* initData() {
  let fakeData = fakeinitdata;
  if (fakeinitdata.productQuantity.length === 0) {
    fakeData = fake;
  }
  yield put({
    type: actions.INIT_DATA,
    payload: fakeData
  });
}
export function* updateData({ products, productQuantity }) {
  localStorage.setItem('cartProductQuantity', JSON.stringify(productQuantity));
  localStorage.setItem('cartProducts', JSON.stringify(products));
  yield put({
    type: actions.UPDATE_DATA,
    products,
    productQuantity
  });
}
export default function* Saga() {
  yield all([
    takeEvery(actions.INIT_DATA_SAGA, initData),
    takeEvery(actions.UPDATE_DATA_SAGA, updateData)
  ]);
}
