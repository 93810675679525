import typeStyleMap from 'lib/graph-vis/typeStyleMap';
import { ReactComponent as SvgImage } from '../../../public/images/svg/choice.svg';

export const ChoiceIcon = (props) => {
  return (
    <SvgImage
      fill={'currentColor'}
      height={props.height || 24}
      style={{ fill: typeStyleMap.choiceNode.color }}
      {...props}
    />
  );
};

export const ChoiceTagIcon = (props) => (
  <span role="img" aria-label="choice tag" className="anticon anticon-choice-tag">
    <SvgImage style={{ margin: '2px 0 -2px 0' }} height={15} fill={'antiquewhite'} {...props} />
  </span>
);

export default ChoiceIcon;
