import typeStyleMap from 'lib/graph-vis/typeStyleMap';
import ChoiceIcon from './choice';
import DomainIcon from './domain';
import ItemIcon from './item';
import ProductOptionIcon from './option';
import PlatformIcon from './platform';
import ProcessIcon from './process';
import ProductIcon from './product';
import ProgramIcon from './program';
import ProjectIcon from './project';
import UnitIcon from './unit';
export * from './choice';
export * from './domain';
export * from './item';
export * from './option';
export * from './order';
export * from './platform';
export * from './process';
export * from './product';
export * from './program';
export * from './project';
export * from './unit';

export const typeNameFromNodeType = {
  choice: 'choice',
  choiceNode: 'choice',
  productOptionChoice: 'choice',
  domain: 'domain',
  domainNode: 'domain',
  item: 'item',
  itemNode: 'item',
  part: 'item',
  option: 'option',
  productOptionNode: 'option',
  productOption: 'option',
  platform: 'platform',
  platformNode: 'platform',
  process: 'process',
  processNode: 'process',
  product: 'product',
  productNode: 'product',
  program: 'program',
  programNode: 'program',
  project: 'project',
  projectNode: 'project',
  unit: 'unit',
  unitNode: 'unit'
};
export const getIconByType = (type: string, size = 20, color = undefined) => {
  switch (typeNameFromNodeType[type]) {
    case 'choice':
      return <ChoiceIcon height={size} color={color || typeStyleMap[type]} />;
    case 'domain':
      return <DomainIcon height={size} color={color || typeStyleMap[type]} />;
    case 'item':
      return <ItemIcon height={size} color={color || typeStyleMap[type]} />;
    case 'option':
      return <ProductOptionIcon height={size} color={color || typeStyleMap[type]} />;
    case 'process':
      return <ProcessIcon height={size} color={color || typeStyleMap[type]} />;
    case 'platform':
      return <PlatformIcon height={size} color={color || typeStyleMap[type]} />;
    case 'product':
      return <ProductIcon height={size} color={color || typeStyleMap[type]} />;
    case 'program':
      return <ProgramIcon size={size} color={color || typeStyleMap[type]} />;
    case 'project':
      return <ProjectIcon height={size} color={color || typeStyleMap[type]} />;
    case 'unitNode':
      return <UnitIcon height={size} color={color || typeStyleMap[type]} />;
    default:
      return null;
  }
};

export const WEVRIcons = {
  ChoiceIcon,
  DomainIcon,
  ItemIcon,
  PlatformIcon,
  ProductIcon,
  ProgramIcon,
  ProjectIcon,
  ProductOptionIcon,
  UnitIcon,
  getIconByType
};
export default getIconByType;
