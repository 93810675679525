import { PERMISSIONS } from '@wevr/common';
import useWEVRUser from 'lib/hooks/user/useWEVRUser';
import { clientSdk, serverSdk } from 'lib/sdk';
import { pathsWithTours } from 'lib/tours';
import { createContext, useEffect, useState } from 'react';

export const GlobalContext = createContext({
  isAdmin: false,
  isBLOX: false,
  isBomMaster: false,
  isLoading: false,
  isPartMaster: false,
  isTagManager: false,
  user: null,
  permissions: [],
  hasPermission: (permission) => null,
  searchIndex: 'prod_one',
  getSdk: (role) => null
});

export const GlobalProvider = ({ children }) => {
  const {
    hasRole,
    isAdmin,
    isBLOX,
    isBomMaster,
    isLoading,
    isPartMaster,
    user,
    permissions,
    hasPermission
  } = useWEVRUser();

  const [searchIndex, setSearchIndex] = useState('prod_one');

  const [isTourOpen, setIsTourOpen] = useState(false);

  const getSdk = (role) => {
    return window ? clientSdk(role) : serverSdk;
  };

  const isTagManager = hasPermission(PERMISSIONS.CAN_EDIT_TAGS);

  useEffect(() => {
    fetch('/api/config')
      .then((res) => res.json())
      .then((res) => {
        setSearchIndex(res.search_index);
      });
  }, []);

  const provider = {
    isTourOpen,
    setIsTourOpen,
    pathsWithTours,
    isAdmin,
    isBLOX,
    isBomMaster,
    isTagManager,
    isLoading,
    isPartMaster,
    user,
    permissions,
    hasPermission,
    hasRole,
    searchIndex,
    getSdk
  };
  return (
    <GlobalContext.Provider value={provider}>{children}</GlobalContext.Provider>
  );
};
