import { GraphQLClient } from 'graphql-request';
export { gql } from 'graphql-request';

const GRAPHURL = '/api/graphql';
const HASURA_CLAIMS_ROLE_KEY = 'x-hasura-role';

const hasuraBareClient = new GraphQLClient(GRAPHURL, {
  headers: {}
});

export const getGraphQLClient = (role) => {
  if (role) {
    const client = new GraphQLClient(GRAPHURL, {
      headers: {}
    });
    client.setHeader(HASURA_CLAIMS_ROLE_KEY, role);
    return client;
  }
  return hasuraBareClient;
};

// export const hasuraUserClient = (role) => {
//   let token;

//   if (typeof window !== 'undefined') {
//     token = tokenSvc.getCurrentToken();
//     if (token) {
//       token = tokenSvc.clearIfExpired(token);
//     }

//     if (!token) {
//       fetch('/api/auth/token')
//         .then((res) => res.json())
//         .then((fullToken) => {
//           tokenSvc.saveToken(fullToken.idToken);
//           return fullToken.idToken;
//         });
//       token = tokenSvc.getCurrentToken();
//     }
//   }

//   let headers = {
//     ...(token && { Authorization: `Bearer ${token}` }),
//   };

//   if (role) {
//     headers['x-hasura-role'] = role;
//   }

//   return new GraphQLClient(publicRuntimeConfig.GRAPHQL_ENDPOINT, {
//     headers,
//   });
// };
