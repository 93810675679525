import getIconByType from 'components/graph/icons';
import BomTagList from 'components/tags/BomTagList';
import { clientSdk } from 'lib/sdk';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const sdk = clientSdk();

const useTagBomTree = () => {
  const [treeData, setTreeData] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [treeLoading, setTreeLoading] = useState(false);
  const [tagSetMap, setTagSetMap] = useState({});
  const [path, setPath] = useState('');
  const {
    error,
    isLoading,
    refetch: refreshTree
  } = useQuery('Tag Path Tree', () => fetchTree(), {
    onSuccess: ({ tag_set, tag_path }) => {
      setTreeLoading(true);
      setTagSetMap(tag_set);

      // setTreeLoading(true);
      if (!tag_path) {
        setTreeData((prev) => prev);
        setTreeLoading(false);
        return;
      }

      const myData = tag_path?.map((t) => mapToTreeNode(t, tag_path));

      setTreeData(myData);
      //setTreeLoading(false);
      setTreeLoading(false);
    }
  });

  const searchTree = useCallback((elements, matchingPath) => {
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].path === matchingPath) {
        return elements[i];
      }
      if (elements[i].children) {
        const result = searchTree(elements[i].children, matchingPath);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }, []);

  const setSelectedByPath = useCallback(
    (path) => {
      if (!path || !treeData) return;
      const node = searchTree(treeData, path);
      setPath(path);
      if (node) {
        setSelectedNode((prev) => node);
      }
    },
    [treeData, searchTree, setSelectedNode]
  );

  useEffect(() => {
    if (path) {
      setSelectedByPath(path);
    }
  }, [path, setSelectedByPath]);

  const mapToTreeNode = (t, tagMap) => {
    const lastSegment = t.tags[t.tags.length - 1];
    const isChoices = lastSegment.tag_type_value === 'CHOICE';
    const icon = isChoices ? <></> : getIconByType(lastSegment?.tag_type_value.toLowerCase());

    t.title = () => (isChoices ? segmentToTags(t.tags) : lastSegment.tag_value);
    t.icon = icon;
    t.children = t.children.map((t) => mapToTreeNode(t, tagMap));

    return t;
  };

  const fetchTree = async () => {
    const res = await fetch("/api/bom/tree");
    return res.json();
  };

  const segmentToTagStructure = (segment) => {
    return {
      id: segment.tag_id,
      tag_type_id: segment.tag_type_value,
      name: segment.tag_name,
      value: segment.tag_value,
      sequence: segment.sort_order
    };
  };

  const displayFromTagPath = (path) => {
    const node = searchTree(treeData, path);
    return node ? node.tags.map((t) => t.value).join(' - ') : '';
  };

  const segmentToTags = (segments) => {
    const isListable = segments[segments.length - 1].tag_type_value === 'CHOICE';
    if (!isListable) {
      segments = segments = [segments[segments.length - 1]];
    } else {
      segments = segments.filter((segment) => segment.tag_type_value === 'CHOICE');
    }
    return <BomTagList tags={segments.map((s) => segmentToTagStructure(s))} compact />;
  };

  return {
    treeData,
    isLoading,
    error,
    setSelectedNode,
    selectedNode,
    refreshTree,
    treeLoading,
    searchTree,
    tagSetMap,
    setSelectedByPath,
    displayFromTagPath
    // setPath
  };
};

export default useTagBomTree;
