import typeStyleMap from 'lib/graph-vis/typeStyleMap';
import { ReactComponent as SvgImage } from '../../../public/images/svg/order.svg';

export const OrderIcon = (props) => (
  <SvgImage
    height={props.height || 24}
    style={{ fill: typeStyleMap.order.color }}
    {...props}
  />
);

export const OrderTagIcon = (props) => (
  <span
    role="img"
    aria-label="platform tag"
    className="anticon anticon-platform-tag"
  >
    <SvgImage
      style={{ margin: '2px 0 -2px 0' }}
      height={15}
      fill={'antiquewhite'}
      {...props}
    />
  </span>
);

export default OrderIcon;
