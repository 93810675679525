import typeStyleMap from 'lib/graph-vis/typeStyleMap';
import { ReactComponent as SvgImage } from '../../../public/images/svg/program.svg';

export const ProgramIcon = (props) => {
  return (
    <SvgImage
      fill={props.color || typeStyleMap.programNode.color}
      height={props.height || 24}
      {...props}
    />
  );
};

export const ProgramTagIcon = (props) => (
  <span
    role="img"
    aria-label="choice tag"
    className="anticon anticon-choice-tag"
  >
    <SvgImage
      style={{ margin: '2px 0 2px 0', padding: '2px 0 0 0' }}
      height={15}
      fill={'white'}
      {...props}
    />
  </span>
);

export default ProgramIcon;
