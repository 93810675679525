//import {ROLES} from 'lib/role-check';
import { GlobalContext } from 'lib/context/GlobalContext';
import useTagBomTree from 'lib/hooks/tags/useTagBomTree';
// import { clientSdk } from 'lib/sdk';
import { createContext, useContext, useEffect, useState } from 'react';
// const sdk = clientSdk();

export const BomContext = createContext({
  audience: 'AE',
  canEdit: false,
  treeData: null,
  treeLoading: false,
  selectedNode: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshTree: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelected: (node) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAudience: (val) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchTree: (elements, path): any => null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedByPath: (path) => {},
  tagSetMap: null,
  legend: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  displayFromTagPath: (path) => {}
});

export const BomProvider = ({ children }) => {
  const { isBomMaster } = useContext(GlobalContext);
  const {
    treeData,
    selectedNode,
    setSelectedNode,
    treeLoading,
    searchTree,
    setSelectedByPath,
    refreshTree,
    tagSetMap,
    displayFromTagPath
  } = useTagBomTree();

  const [audience, setAudience] = useState('AE');
  const canEdit = isBomMaster;
  const [legend] = useState(null);

  useEffect(() => {
    //setSelectedNode(treeData[0]);
  }, [treeData]);

  const setSelected = (node) => {
    setSelectedNode(node);
  };

  const provider = {
    audience,
    setAudience,
    canEdit,
    treeData,
    refreshTree,
    treeLoading,
    selectedNode,
    setSelected,
    searchTree,
    setSelectedByPath,
    displayFromTagPath,
    tagSetMap,
    legend
  };
  return <BomContext.Provider value={provider}>{children}</BomContext.Provider>;
};
