import { toTitleCase } from '@wevr/common';
import { Tag, Tooltip } from 'antd';
import {
  ChoiceTagIcon,
  DomainTagIcon,
  OrderTagIcon,
  PlatformTagIcon,
  ProcessTagIcon,
  ProductTagIcon,
  ProgramTagIcon,
  ProjectTagIcon
} from 'components/graph/icons';
import * as _ from 'lodash';
import React from 'react';

const { CheckableTag } = Tag;

export interface ITag {
  id: number;
  tag_type_id: string;
  name: string;
  value?: string;
  label?: string;
  sequence?: number;
  notes?: string;
  sort_order?: number;
}

export const tagColors = {
  PLATFORM: '#ef8c22',
  PRODUCT: '#b291b5',
  PROJECT: '#6db1ff',
  CHOICE: '#83BBe5',
  PROCESS: '#c92d39',
  PROGRAM: '#1071e5',
  DOMAIN: '#dfe3e8',
  MARKET: '#dfe3e8',
  ORDER: '#c92d39',
  UNIT: '#dedeff'
};

export const icons = {
  CHOICE: <ChoiceTagIcon />,
  DOMAIN: <DomainTagIcon />,
  MARKET: <DomainTagIcon />,
  ORDER: <OrderTagIcon />,
  PROCESS: <ProcessTagIcon />,
  PRODUCT: <ProductTagIcon />,
  PROJECT: <ProjectTagIcon />,
  PROGRAM: <ProgramTagIcon style={{ paddingTop: '2px' }} />,
  PLATFORM: <PlatformTagIcon />,
  UNIT: <ProductTagIcon />
};

const FACET_NAMES = {
  DOMAIN: 'tagged_domains',
  MARKET: 'tagged_domains',
  PLATFORM: 'tagged_platforms',
  PRODUCT: 'tagged_products',
  CHOICE: 'tagged_choices',
  PROCESS: 'tagged_processes'
};

const FACET_PREFIXES = {
  DOMAIN: 'Market - ',
  PLATFORM: 'Product Line - ',
  PRODUCT: 'Product - ',
  PROCESS: 'Process - '
};

// const getFacetName = (tagTypeId: string) => {
//   const name = FACET_NAMES[tagTypeId];
// };
export const getFacet = (tag: ITag) => {
  if (tag.tag_type_id === 'CHOICE') {
    return FACET_NAMES[tag.tag_type_id] + ':' + `${tag.name} - ${tag.value}`;
  }
  const prefix = FACET_NAMES[tag.tag_type_id] + ':' + FACET_PREFIXES[tag.tag_type_id];
  return prefix ? prefix + tag.value : `${tag.name} - ${tag.value}`;
};

export const facetSetFromTagSet = (tagSet: ITag[]) => {
  const retVal = [];
  const groups = _.groupBy(tagSet, 'tag_type_id');
  // platforms
  if (groups.PLATFORM) {
    // differes fron others as an array or "OR" set.
    retVal.push(groups.PLATFORM.map((tag) => getFacet(tag)));
  }
  // products
  if (groups.PRODUCT) {
    retVal.push(...groups.PRODUCT.map((tag) => getFacet(tag)));
  }
  // choices
  if (groups.CHOICE) {
    retVal.push(...groups.CHOICE.map((tag) => getFacet(tag)));
  }
  // processes
  if (groups.PROCESS) {
    retVal.push(...groups.PROCESS.map((tag) => getFacet(tag)));
  }
  return retVal;
};

export const getTagTypeId = (tag: ITag) => {
  return tag.tag_type_id;
};

export const getTagName = (tag: ITag) => {
  return tag.name;
};

export const getTagValue = (tag: ITag) => {
  return tag.value;
};

export const getTagLabel = (tag: ITag) => {
  return tag.label;
};

export const getTagSequence = (tag: ITag) => {
  return tag.sequence;
};

export const getTagSortOrder = (tag: ITag) => {
  return tag.sort_order;
};

export const getTag = (tag: ITag) => {
  return tag;
};

export const getTagTypeIdFromTag = (tag: ITag) => {
  return tag.tag_type_id;
};

export const getTagNameFromTag = (tag: ITag) => {
  return tag.name;
};

export const getTagValueFromTag = (tag: ITag) => {
  return tag.value;
};

export const getTagLabelFromTag = (tag: ITag) => {
  return tag.label;
};

export const getTagSequenceFromTag = (tag: ITag) => {
  return tag.sequence;
};

export const getTagSortOrderFromTag = (tag: ITag) => {
  return tag.sort_order;
};

export const getTagFromTag = (tag: ITag) => {
  return tag;
};

export const getTypeName = (tag_type_id) => {
  if (tag_type_id === 'DOMAIN') {
    return 'Market';
  } else if (tag_type_id === 'PLATFORM') {
    return 'Product Line';
  } else {
    return toTitleCase(tag_type_id);
  }
};

export const BomTag = ({
  tag,
  compact = true,
  withIcon = true,
  typeName = null,
  style = null,
  checkable = false,
  checked = false,
  onSelect = null,
  ...props
}) => {
  const { tag_type_id, name, value = null, label = null } = tag;

  const textCompact = value ? value : name;
  const textWide = label || value ? `${name}: ${value}` : name;
  const tooltip = label || textWide;
  const icon = withIcon ? icons[typeName || tag_type_id] : null;
  const color = tagColors[typeName || tag_type_id || null];

  return (
    <Tooltip title={tooltip}>
      {!checkable && (
        <Tag
          className={`custom-tag-${tag.tag_type_id.toLocaleLowerCase()}`}
          color={color}
          icon={icon}
          {...props}
          style={{ userSelect: 'none', ...style }}
        >
          {compact ? textCompact : textWide}
        </Tag>
      )}
      {checkable && (
        <CheckableTag checked={checked} onChange={(checked) => onSelect(tag, checked)}>
          <Tag
            className={`custom-tag-${tag.tag_type_id.toLocaleLowerCase()}`}
            color={color}
            icon={icon}
            style={{
              userSelect: 'none',
              margin: '0px',
              padding: '2px',
              ...style
            }}
            {...props}
          >
            {compact ? textCompact : textWide}
          </Tag>
        </CheckableTag>
      )}
    </Tooltip>
  );
};

export default BomTag;
