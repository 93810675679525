import typeStyleMap from 'lib/graph-vis/typeStyleMap';
import { ReactComponent as SvgImage } from '../../../public/images/svg/domain.svg';
export const DomainIcon = (props) => (
  <SvgImage
    height={props.height || 24}
    style={{ fill: typeStyleMap.domainNode.color }}
    {...props}
  />
);

export const DomainTagIcon = (props) => (
  <span
    role="img"
    aria-label="domain tag"
    className="anticon anticon-domain-tag"
  >
    <SvgImage
      style={{ margin: '2px 0 -2px 0' }}
      height={15}
      fill={'black'}
      {...props}
    />
  </span>
);

export default DomainIcon;
