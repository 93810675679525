import App from '@iso/redux/app/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import { combineReducers } from 'redux';
// import Auth from '../authentication/reducer';

export default combineReducers({
  // Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Ecommerce,
});
