export function wevrCommon(): string {
  return 'wevr-common';
}

export function toTitleCase(str) {
  return str ? str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  ).replace('_', ' ') : '';
}

export const groupBy = (input, key) => {
  return input.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};
