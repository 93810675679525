import typeStyleMap from 'lib/graph-vis/typeStyleMap';
import { ReactComponent as SvgImage } from '../../../public/images/svg/unit.svg';
export const UnitIcon = (props) => (
  <SvgImage height={props.height || 24} style={{ fill: typeStyleMap.unitNode.color }} {...props} />
);

export const UnitTagIcon = (props) => (
  <span role="img" aria-label="unit tag" className="anticon anticon-unit-tag">
    <SvgImage style={{ margin: '2px 0 -2px 0' }} height={15} fill={'#000'} {...props} />
  </span>
);

export default UnitIcon;
