import { Divider } from 'antd';
import React from 'react';
import BomTag, { ITag } from './BomTag';

interface ITagListProps {
  title?: string;
  tags: unknown[];
  compact?: boolean;
  orientation?: 'left' | 'right' | 'center';
  checkable?: boolean;
  onSelect?: (tag: ITag, checked: boolean) => void;
  selected?: number[];
  style?: React.CSSProperties;
  withIcon?: boolean;
  bare?: boolean;
}
export const TagList = ({
  title,
  tags,
  orientation,
  checkable,
  onSelect = null,
  selected = null,
  style = null,
  bare = false,
  ...props
}: Partial<ITagListProps>) => {
  const tagList = tags.map((t: { id: string | number }) => (
    <BomTag
      key={t.id}
      tag={t}
      style={{ userSelect: 'none', ...style }}
      checkable={checkable}
      onSelect={onSelect}
      checked={selected ? !!selected.find((s) => s === t.id) : null}
      {...props}
    />
  ));

  if (bare) {
    return <>{tagList}</>;
  }

  return (
    <div style={{ width: '100%' }}>
      {title && <Divider orientation={orientation || 'left'}>{title}</Divider>}
      {/* <Space> */}
      {tagList}
      {/* </Space> */}
    </div>
  );
};

export default TagList;
